'use client';

import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Link} from "@/i18n/routing";


export interface ClientSwiperItem {
    imgLink: string;
    label: string;
    url?: string | null;
}


interface ClientSwiperSectionProps {
	spaceBetween?: number;
	slidesPerView?: number;
	loop?: boolean;
	breakpoints?: any;
	items: ClientSwiperItem[];
}



const ClientSwiperSection: FC<ClientSwiperSectionProps> = ({
	items,
	spaceBetween = 30,
	slidesPerView = 2,
	loop = true,
	breakpoints = {
		576: { slidesPerView: 2 },
		768: { slidesPerView: 3 },
		992: { slidesPerView: 4 },
		1200: { slidesPerView: 6 },
	},
}) => {
	return (
		<Swiper spaceBetween={spaceBetween} slidesPerView={slidesPerView} breakpoints={breakpoints} loop={loop} wrapperClass='d-flex justify-content-center align-items-center'>
			{items.map((item, index) => (
				<SwiperSlide className="swiper-slide align-self-center" key={index}>
					{item.url == null ? (
						<picture>
							<img src={item.imgLink} className="grayscale px-sm-4 ps-0" alt={item.label} />
						</picture>
					) : (
						<Link href={item.url} target='_blank'>
							<picture>
								<img src={item.imgLink} className="grayscale px-sm-4 ps-0" alt={item.label} />
							</picture>
						</Link>
					)}
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default ClientSwiperSection;
